import { useEffect, useMemo, useState } from 'react';
import QuizFail from '../fail';
import QuizPass from '../pass';
import QuizQuestion from '../question';
import useFullscreen from '../useFullscreen';
import useVisibilityChange from '../useVisibilityChange';
import axios from 'axios';
import {
	API_ENDPOINTS,
	route,
	StringHelpers,
	ThemeLogo,
	Button,
	useDisabledContext,
	Variants,
	ButtonTypes,
	useLangContext,
	useNavigationGuard,
	getThemeFromUrl,
	Themes,
} from 'carrier-fe';
import { useParams } from 'react-router-dom';
import './styles.css';

const Quiz = {
	QUESTIONS: 'QUESTIONS',
	FAIL: 'FAIL',
	PASS: 'PASS',
} as const;

const DEBUG = true;

const MAX_WIDTH = 600;

export interface QuestionInterface {
	question_no: number;
	question: string;
	options: string[];
}

export interface QuizDetails {
	id: string;
	attendee_id: string;
	training_quiz_id: string;
	training_quiz_name: string;
	training_quiz_description: string;
	status: 'Ongoing' | 'Failed' | 'Passed';
	no_of_questions: number;
	no_of_answered_questions: number;
	last_answered_at: string | null;
	created_at: string;
	updated_at: string;
	deleted_at: string | null;
}

function QuizWrapper() {
	const visible = useVisibilityChange();
	const { isFullscreen, closeFullscreen } = useFullscreen();
	const { setDisabled } = useDisabledContext();
	const { quiz_session_id } = useParams();

	const [quizState, setQuizState] = useState<keyof typeof Quiz>(
		Quiz.QUESTIONS
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [mountedQuestion, setMountedQuestion] = useState<QuestionInterface>();
	const [mountedAnswer, setMountedAnswer] = useState<string>('');
	const [completed, setCompleted] = useState(false);
	const [quizDetails, setQuizDetails] = useState<QuizDetails>();
	const { quiz } = useLangContext();
	const { disabled } = useDisabledContext();

	const quizSessionId = useMemo(() => {
		if (!quiz_session_id) return '';
		return quiz_session_id;
	}, [quiz_session_id]);

	const view = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.VIEW, {
					quizSessionId,
				})
			)
			.then((res) => {
				setQuizDetails(res.data.data);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const next = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.QUESTION.VIEW, {
					quizSessionId,
				})
			)
			.then((res) => {
				if (res.data.data.question_no !== null) {
					setMountedQuestion(res.data.data);
					setMountedAnswer('');
				} else {
					setCompleted(true);
				}
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const answer = () => {
		if (isLoading) return;
		setIsLoading(true);

		const payload = {
			question_no: mountedQuestion?.question_no,
			answer: mountedAnswer,
		};

		axios
			.patch(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.QUESTION.ANSWER, {
					quizSessionId,
				}),
				payload
			)
			.then(() => {
				next();
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const complete = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.patch(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.COMPLETE, {
					quizSessionId,
				})
			)
			.then((res) => {
				if (isFullscreen) closeFullscreen();

				if (res.data.data.status === 'Failed') {
					setQuizState(Quiz.FAIL);
				} else {
					setQuizState(Quiz.PASS);
				}
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const quit = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.patch(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.QUIT, {
					quizSessionId,
				})
			)
			.then(() => {
				setQuizState(Quiz.FAIL);
				if (isFullscreen) closeFullscreen();
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const getHeight = (): string => {
		switch (getThemeFromUrl()) {
			case Themes.Viessmann:
				return '80px';
			default:
				return '60px';
		}
	};

	const totalQuestionsNumber = useMemo(
		() => quizDetails?.no_of_questions || 0,
		[quizDetails]
	);
	const currentQuestionNumber = useMemo(
		() => mountedQuestion?.question_no || 0,
		[mountedQuestion]
	);

	// Used for browser confirmation not the actual message displayed
	useNavigationGuard({
		message: 'Are you sure?',
	});

	useEffect(() => {
		if (!visible && !DEBUG) quit();
	}, [visible]);

	useEffect(() => {
		setDisabled(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (quizSessionId) next();
	}, [quizSessionId]);

	useEffect(() => {
		if (completed) complete();
	}, [completed]);

	useEffect(() => {
		view();
	}, []);

	const renderPage = () => {
		switch (quizState) {
			case Quiz.PASS:
				return <QuizPass maxWidth={MAX_WIDTH} />;
			case Quiz.FAIL:
				return <QuizFail maxWidth={MAX_WIDTH} />;
			case Quiz.QUESTIONS:
			default:
				return (
					<QuizQuestion
						answer={answer}
						mountedQuestion={mountedQuestion}
						mountedAnswer={mountedAnswer}
						setMountedAnswer={setMountedAnswer}
						totalQuestions={totalQuestionsNumber}
						currentQuestion={currentQuestionNumber}
						maxWidth={MAX_WIDTH}
					/>
				);
		}
	};

	return (
		<div className="dashboard--wrapper d-flex vh-100">
			<div className="dashboard--page--wrapper w-100 d-flex flex-column">
				<div className="d-flex align-items-center border-bottom py-3 px-4 bg-white gap-4">
					<ThemeLogo
						theme={getThemeFromUrl()}
						style={{ height: getHeight() }}
					/>
					<h1 className="d-none d-lg-block">
						{StringHelpers.title(
							quizDetails?.training_quiz_name || 'Quiz name'
						)}
					</h1>
					{quizState === Quiz.QUESTIONS && (
						<div className="ms-auto">
							<Button
								label={StringHelpers.title(
									quiz?.exit || 'exit'
								)}
								onClick={quit}
								disabled={disabled}
								variant={Variants.Dark}
								type={ButtonTypes.Outline}
							/>
						</div>
					)}
				</div>
				{quizState === Quiz.QUESTIONS && (
					<progress
						value={currentQuestionNumber}
						max={totalQuestionsNumber}
					></progress>
				)}
				<div className="dashboard--child--wrapper">{renderPage()}</div>
			</div>
		</div>
	);
}

export default QuizWrapper;
