import {
	PageLayout,
	Card,
	CardBody,
	Button,
	CardPlaceholder,
	Icon,
	Icons,
	useAuthorisationContext,
	useLangContext,
	StringHelpers,
} from 'carrier-fe';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
	const { hasPolicyAccess } = useAuthorisationContext();

	const { getLangPage } = useLangContext();
	const [lang, setLang] = useState<any>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('training/dashboard')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title ?? 'Dashboard'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	};

	const renderCoursesTile = () => {
		if (!hasPolicyAccess({ name: 'training_course', method: 'viewAny' }))
			return null;

		let content = getTileById('training.course.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.PROFILE}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Courses'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						className="d-inline-flex"
						onClick={() => navigate('/training/course')}
					/>
				</CardBody>
			</Card>
		);
	};

	const renderEventsTile = () => {
		if (!hasPolicyAccess({ name: 'training_event', method: 'viewAny' }))
			return null;

		let content = getTileById('training.event.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.APARTMENT}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'events'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate('/training/event')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderResultsTile = () => {
		if (!hasPolicyAccess({ name: 'training_result', method: 'viewAny' }))
			return null;

		let content = getTileById('training.result.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Results'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate('/training/result')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderCoursesTile()}
						{renderEventsTile()}
						{renderResultsTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
