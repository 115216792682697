import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	route,
	StringHelpers,
	SubTitle,
	useLangContext,
} from 'carrier-fe';
import { useNavigate, useParams } from 'react-router-dom';

function UpcomingEvents() {
	const navigate = useNavigate();
	const { crud, fields } = useLangContext();
	const { id } = useParams();

	const handleOptionSelect = (action: any, data: any) => {
		const { id = '' } = data;

		const actions: { [key: string]: () => void } = {
			view: () => navigate(`/training/event/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const colMapping = (data: any) => {
		const cols: any = [
			{
				id: 'name',
				label: StringHelpers.title(fields?.name || 'Name'),
				value: StringHelpers.title(data.name || '-'),
				sortable: true,
			},
			{
				id: 'event_at',
				label: StringHelpers.title(fields?.event_at || 'Date'),
				value: formatDate(data.event_at) || '-',
				sortable: true,
			},
			{
				id: 'price',
				label: StringHelpers.title(fields?.price || 'Price'),
				value: data.display_price || '-',
				sortable: true,
			},
			{
				id: 'availability_percentage',
				label: StringHelpers.title(
					fields?.availability || 'Availability'
				),
				value: `${data.availability_percentage}%` || '-',
				sortable: true,
			},
			{
				id: 'joined',
				label: StringHelpers.title(fields?.joined || 'Joined'),
				value: !!data.joined ? 'Y' : 'N',
				sortable: true,
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(
							fields?.select || 'Select'
						)}
						name="actions"
						value=""
						onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<>
			<SubTitle
				title={StringHelpers.title(
					crud?.sub_titles?.upcoming_events || 'Upcoming Events'
				)}
				className="mb-4"
				style={{ color: '#464C5E' }}
			/>
			<DataTable<any>
				routeEndpoint={route(
					API_ENDPOINTS.TRAINING.COURSE.UPCOMING_EVENTS,
					{
						trainingCourse: String(id),
					}
				)}
				colMapping={colMapping}
				incrementPaginationButtons
			/>
		</>
	);
}

export default UpcomingEvents;
