import {
	Button,
	Card,
	CardBody,
	StringHelpers,
	useLangContext,
	BottomAnchoredControls,
	WidthConstrainedContainer,
} from 'carrier-fe';
import SuccessIcon from '../icons/pass';
import { useNavigate, useParams } from 'react-router-dom';

function QuizPass(props: { maxWidth: number }) {
	const { training_course_id } = useParams();
	const navigate = useNavigate();
	const { quiz } = useLangContext();
	const { maxWidth } = props;

	return (
		<>
			<WidthConstrainedContainer maxWidth={maxWidth}>
				<Card>
					<CardBody>
						<div className="text-center">
							<SuccessIcon />
							<h1>
								{StringHelpers.title(
									quiz?.passed || 'Quiz Passed'
								)}
							</h1>
							<p>{quiz.success_subtitle || ''}</p>
						</div>
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={StringHelpers.title(quiz.close || 'Close')}
					onClick={() =>
						navigate(
							`/training/course/${training_course_id}/progress`
						)
					}
				/>
			</BottomAnchoredControls>
		</>
	);
}

export default QuizPass;
