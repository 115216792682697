import { useState, useCallback, useEffect } from "react";

const FULLSCREEN_CHANGE = "fullscreenchange"
const WEBKIT_FULLSCREEN_CHANGE = "webkitfullscreenchange"
const MOZILLA_FULLSCREEN_CHANGE = "mozfullscreenchange"
const MICROSOFT_FULLSCREEN_CHANGE = "MSFullscreenChange"

function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const doc = document as any;

  const openFullscreen = useCallback((element: any = document.documentElement) => {
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen(); // Firefox
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Chrome, Safari and Opera
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE/Edge
      }
    }
  }, [isFullscreen])

  const closeFullscreen = useCallback(() => {
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen(); // Firefox
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen(); // Chrome, Safari and Opera
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen(); // IE/Edge
    }
  }, [isFullscreen])

  // Effect to listen for fullscreen state changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!(
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement
      ));
    };

    document.addEventListener(FULLSCREEN_CHANGE, handleFullscreenChange);
    document.addEventListener(WEBKIT_FULLSCREEN_CHANGE, handleFullscreenChange);
    document.addEventListener(MOZILLA_FULLSCREEN_CHANGE, handleFullscreenChange);
    document.addEventListener(MICROSOFT_FULLSCREEN_CHANGE, handleFullscreenChange);

    return () => {
      document.removeEventListener(FULLSCREEN_CHANGE, handleFullscreenChange);
      document.removeEventListener(WEBKIT_FULLSCREEN_CHANGE, handleFullscreenChange);
      document.removeEventListener(MOZILLA_FULLSCREEN_CHANGE, handleFullscreenChange);
      document.removeEventListener(MICROSOFT_FULLSCREEN_CHANGE, handleFullscreenChange);
    };
  }, []);

  return { isFullscreen, openFullscreen, closeFullscreen };
}

export default useFullscreen;

