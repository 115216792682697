import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	PageLayout,
	route,
	Variants,
	useDisabledContext,
	StringHelpers,
	useLangContext,
	ButtonTypes,
	Badge,
	Modal,
	Icons,
	Icon,
	ModalSizes,
	Alert,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';

type ModuleType = {
	id: string;
	name: string;
	description: string;
	type: string;
	external_url: string;
	training_quiz_id: string;
	status: string;
	file: {
		id: string;
		url: string;
		type: string;
		size_bytes: number;
		size_human: string;
		name: string;
		extension: string;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	updated_at: string;
	deleted_at: string;
	order: number;
};

type StageType = {
	id: string;
	name: string;
	description: string;
	status: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	training_modules: ModuleType[];
};

type CourseType = {
	name: string;
	requires_in_person_event: string;
	training_stages: StageType[];
};

function TrainingCourseProgress() {
	const { disabled } = useDisabledContext();
	const { id } = useParams();
	const navigate = useNavigate();

	const [trainingCourse, setTrainingCourse] = useState<CourseType | null>(
		null
	);
	const [loading, setLoading] = useState<boolean>(true);
	const [module, setModule] = useState<ModuleType | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [url, setUrl] = useState<string>('');

	const [collapsedStages, setCollapsedStages] = useState<
		Record<string, boolean>
	>({});

	const { crud, fields } = useLangContext();

	useEffect(() => {
		fetchTrainingCourse();
	}, [id]);

	useEffect(() => {
		if (trainingCourse) {
			const inProgressStage = trainingCourse.training_stages.find(
				(stage) => stage.status === 'in progress'
			);
			setCollapsedStages({ [inProgressStage?.id || '']: true });
		}
	}, [trainingCourse]);

	const fetchTrainingCourse = async () => {
		try {
			const response = await axios.get(
				route(API_ENDPOINTS.TRAINING.COURSE.SESSIONS, {
					trainingCourse: String(id),
				})
			);
			setTrainingCourse(response.data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleToggleCollapse = (stageId: string) => {
		setCollapsedStages((prev) => ({ ...prev, [stageId]: !prev[stageId] }));
	};

	const handleModuleAction = async (stage: StageType, module: ModuleType) => {
		setModule(module);

		if (module.type === 'quiz' && module.training_quiz_id) {
			window.open(
				`/training/course/${id}/quiz/${module.training_quiz_id}/info`,
				'_blank'
			);
			return;
		}

		try {
			const response = await axios.get(
				route(API_ENDPOINTS.TRAINING.COURSE.RESOURCE, {
					trainingCourse: String(id),
					trainingCourseStage: stage.id,
					trainingCourseModule: module.id,
				})
			);

			if (module.type === 'youtube') {
				setUrl(response.data.url);
				setIsOpen(true);
			} else {
				// TODO - Trigger download automatically
				window.open(response.data.url);
			}
		} catch (error) {
			console.error(error);
		} finally {
			fetchTrainingCourse();
		}
	};

	const title = loading
		? ''
		: StringHelpers.title(trainingCourse?.name || 'Training Event View');

	return (
		<PageLayout
			title={title}
			loading={loading}
		>
			{!trainingCourse ? (
				<p>
					{StringHelpers.title(
						fields.load_page ||
							'Could not load page, please refresh and try again.'
					)}
				</p>
			) : (
				<>
					<Button
						label={StringHelpers.title(
							crud?.buttons?.back?.default || 'Back'
						)}
						onClick={() => navigate(`/training/course/${id}`)}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						className="mb-4"
						disabled={disabled}
					/>

					{trainingCourse.requires_in_person_event === '1' && (
						<Alert className="mb-4">
							{StringHelpers.title(
								fields.in_person_event_outcome ||
									'This course will be completed based on the outcomes of the in-person event.'
							)}
						</Alert>
					)}

					{trainingCourse.training_stages.length > 0 ? (
						trainingCourse.training_stages.map((stage, index) => {
							const isCollapsed =
								collapsedStages[stage.id] || false;

							return (
								<Card key={stage.id}>
									<CardBody>
										<div className="d-flex align-items-center justify-content-between">
											<div>
												<h6 className="text-uppercase fw-bold text-primary fs-6">
													Stage {index + 1}
												</h6>
												<div className="d-flex gap-4 mb-1">
													<h4 className="mb-0">
														{stage.name}
													</h4>
													{stage.status && (
														<Badge
															label={StringHelpers.title(
																stage.status
															)}
															variant={
																stage.status ===
																'completed'
																	? Variants.Success
																	: Variants.Primary
															}
														/>
													)}
												</div>
												{stage.description && (
													<p className="text-muted mb-0">
														{StringHelpers.limit(
															stage.description,
															200
														)}
													</p>
												)}
											</div>

											<button
												className="btn d-flex align-items-center justify-content-center p-2"
												onClick={() =>
													handleToggleCollapse(
														stage.id
													)
												}
												style={{
													transition:
														'transform 0.2s ease-in-out',
													transform: isCollapsed
														? 'rotate(-180deg)'
														: '',
												}}
											>
												<Icon
													icon={Icons.CHEVRON_DOWN}
												/>
											</button>
										</div>

										{isCollapsed && (
											<div className="mt-4">
												{stage.training_modules.map(
													(module) => (
														<Card
															key={module.id}
															className="mb-2"
														>
															<CardBody className="d-flex align-items-center justify-content-between gap-3 p-2">
																<div className="d-flex align-items-center">
																	<div
																		className="d-inline-flex align-items-center justify-content-center p-2 rounded-2"
																		style={{
																			backgroundColor:
																				module.status ===
																				'completed'
																					? '#EDFCF3'
																					: module.status ===
																					  'in progress'
																					? '#E9EFFB'
																					: '#F2F4F7',
																			color:
																				module.status ===
																				'completed'
																					? '#17B26A'
																					: module.status ===
																					  'in progress'
																					? '#1E82E6'
																					: '#D0D5DD',
																		}}
																	>
																		<Icon
																			icon={
																				module.type ===
																				'quiz'
																					? Icons.LIST
																					: module.type ===
																					  'pdf'
																					? Icons.DESCRIPTION
																					: Icons.PLAY_CIRCLE
																			}
																		/>
																	</div>

																	<div className="ms-3">
																		<h6 className="mb-1">
																			{StringHelpers.limit(
																				module.name,
																				100
																			)}
																		</h6>
																		<p className="text-muted mb-0">
																			{module.type ===
																			'pdf'
																				? StringHelpers.upper(
																						module.type
																				  )
																				: StringHelpers.title(
																						module.type
																				  )}
																		</p>
																		{module.description && (
																			<p className="text-muted mb-0">
																				{StringHelpers.limit(
																					module.description,
																					200
																				)}
																			</p>
																		)}
																	</div>
																</div>

																{module.type !==
																'quiz' ? (
																	<button
																		className="btn p-2 d-inline-flex align-items-center justify-content-center text-muted"
																		type="button"
																		disabled={
																			!module.status
																		}
																		onClick={() =>
																			handleModuleAction(
																				stage,
																				module
																			)
																		}
																	>
																		<Icon
																			icon={
																				module.type ===
																				'youtube'
																					? Icons.OPEN_IN_NEW
																					: Icons.DOWNLOAD
																			}
																		/>
																	</button>
																) : (
																	module.status !==
																		'completed' && (
																		<button
																			className="btn p-2 d-inline-flex align-items-center justify-content-center text-muted"
																			type="button"
																			disabled={
																				!module.status
																			}
																			onClick={() =>
																				handleModuleAction(
																					stage,
																					module
																				)
																			}
																		>
																			<Icon
																				icon={
																					Icons.OPEN_IN_NEW
																				}
																			/>
																		</button>
																	)
																)}
															</CardBody>
														</Card>
													)
												)}
											</div>
										)}
									</CardBody>
								</Card>
							);
						})
					) : (
						<Card>
							<CardBody>
								<p>
									{fields.no_stages_modules ||
										'There are currently no stages & modules for this course.'}
								</p>
							</CardBody>
						</Card>
					)}
				</>
			)}

			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title={StringHelpers.title(module?.name || '')}
				closeText={crud?.buttons?.cancel?.default || 'Cancel'}
				size={ModalSizes.Large}
			>
				<iframe
					width="100%"
					height="350"
					src={url}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowFullScreen
				></iframe>
			</Modal>
		</PageLayout>
	);
}

export default TrainingCourseProgress;
