import React, { useState } from 'react';
import { StageType } from '../../../../types/course';
import {
	Card,
	CardBody,
	Icon,
	Icons,
	StringHelpers,
	SubTitle,
	useLangContext,
} from 'carrier-fe';

type Props = {
	stages: StageType[];
};

function Stages(props: Props) {
	const { stages } = props;
	const [collapsedStages, setCollapsedStages] = useState<{
		[key: string]: boolean;
	}>({});
	const { crud, fields } = useLangContext();

	const toggleCollapse = (stageId: string) => {
		setCollapsedStages((prevState) => ({
			...prevState,
			[stageId]: !prevState[stageId],
		}));
	};

	return (
		<>
			<SubTitle
				title={StringHelpers.title(
					crud?.sub_titles?.stages_modules || 'Stages & Modules'
				)}
				className="mb-4"
				style={{ color: '#464C5E' }}
			/>

			{stages.length > 0 ? (
				stages.map((stage, index) => {
					const isCollapsed = collapsedStages[stage.id] || false;

					return (
						<Card key={index}>
							<CardBody>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										<h6
											className="text-uppercase fw-bold"
											style={{
												color: '#1E82E6',
												fontSize: '14px',
											}}
										>
											Stage {index + 1}
										</h6>
										<h4 className="mb-1">{stage.name}</h4>
										{stage.description && (
											<div
												style={{
													color: '#667085',
												}}
											>
												{StringHelpers.limit(
													stage.description,
													200
												)}
											</div>
										)}
									</div>
									<button
										className="btn d-flex align-items-center justify-content-center p-2"
										type="button"
										aria-expanded={isCollapsed}
										aria-controls={`collapse-${stage.id}`}
										onClick={() => toggleCollapse(stage.id)}
										style={{
											transition:
												'transform 0.2s ease-in-out',
											transform: isCollapsed
												? 'rotate(-180deg)'
												: '',
										}}
									>
										<Icon icon={Icons.CHEVRON_DOWN} />
									</button>
								</div>
								<div
									className={`collapse ${
										isCollapsed ? 'show' : ''
									}`}
									id={`collapse-${stage.id}`}
								>
									<div className="mt-4">
										{stage.training_modules.map(
											(module, index) => (
												<Card
													key={index}
													className="mb-2"
												>
													<CardBody className="d-flex align-items-center p-2">
														<div
															className="d-inline-flex align-items-center justify-content-center p-2 rounded-2"
															style={{
																width: '40px',
																height: '40px',
																backgroundColor:
																	'#E9EFFB',
																color: '#1E82E6',
															}}
														>
															<Icon
																icon={
																	module.type ===
																	'quiz'
																		? Icons.QUIZ
																		: module.type ===
																		  'pdf'
																		? Icons.DESCRIPTION
																		: Icons.PLAY_CIRCLE
																}
															/>
														</div>
														<div className="ms-3">
															<h6 className="mb-1">
																{StringHelpers.title(
																	StringHelpers.limit(
																		module.name,
																		100
																	)
																)}
															</h6>
															{module.description && (
																<div
																	className="mb-1"
																	style={{
																		color: '#667085',
																	}}
																>
																	{StringHelpers.limit(
																		module.description,
																		200
																	)}
																</div>
															)}
															<div
																style={{
																	color: '#667085',
																}}
															>
																{module.type ===
																'pdf'
																	? StringHelpers.upper(
																			module.type
																	  )
																	: StringHelpers.title(
																			module.type
																	  )}
															</div>
														</div>
													</CardBody>
												</Card>
											)
										)}
									</div>
								</div>
							</CardBody>
						</Card>
					);
				})
			) : (
				<Card>
					<CardBody>
						<p className="mb-0">
							{fields.no_stages_modules ||
								'There are currently no stages & modules for this course.'}
						</p>
					</CardBody>
				</Card>
			)}
		</>
	);
}

export default Stages;
