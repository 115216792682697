import { Button, Card, CardBody, CardHeader, StringHelpers, useDisabledContext, BottomAnchoredControls, WidthConstrainedContainer, useLangContext } from "carrier-fe";
import { QuestionInterface } from "../wrapper";
import "./question.css";

interface QuizQuestionProps {
  answer: () => void;
  mountedQuestion: QuestionInterface | undefined;
  mountedAnswer: string;
  setMountedAnswer: (answer: string) => void;
  totalQuestions: number;
  currentQuestion: number;
  maxWidth: number;
}

function QuizQuestion(props: QuizQuestionProps) {

  const { answer, mountedQuestion, mountedAnswer, setMountedAnswer, totalQuestions, currentQuestion, maxWidth } = props;
  const { disabled } = useDisabledContext();
  const { quiz } = useLangContext();

  const renderAnswers = () => {
    return mountedQuestion?.options.map((option, i) => {
      return (
        <label key={'option_' + i} className="d-flex gap-2 p-2">
          <input
            type="radio"
            id={'option_' + i}
            name="quiz"
            checked={mountedAnswer === String(i)}
            onChange={() => setMountedAnswer(String(i))}
            disabled={disabled}
          />
          {option}
        </label>
      )
    })
  }

  return (
    <>
      <WidthConstrainedContainer maxWidth={maxWidth}>
        <Card>
          <CardHeader leftSlot={<>
            <i className="question_marker">{StringHelpers.title(quiz?.question || "Question")} {currentQuestion + 1}/{totalQuestions}</i>
            <h1>{StringHelpers.title(mountedQuestion?.question || "")}</h1>
          </>} />
          <CardBody>
            <form className="d-flex flex-column gap-2">
              {renderAnswers()}
            </form>
          </CardBody>
        </Card>
      </WidthConstrainedContainer>
      <BottomAnchoredControls>
        <Button label={StringHelpers.title(quiz?.next || "Next")} disabled={disabled || mountedAnswer === ''} onClick={answer} />
      </BottomAnchoredControls>
    </>)
}

export default QuizQuestion;

