import { useEffect, useState } from "react";

const VISIBILITY_CHANGE = 'visibilitychange';

function useVisibilityChange() {

  const [isVisible, setIsVisible] = useState(!document.hidden);

  useEffect(() => {
    const event = () => setIsVisible(!document.hidden);

    window.addEventListener(VISIBILITY_CHANGE, event);
    return () => window.removeEventListener(VISIBILITY_CHANGE, event);
  }, [])

  return isVisible;
}

export default useVisibilityChange;
